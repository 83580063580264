body {
    margin: 0;
    padding: 0;
    background: #FAFAFA;
}
.formContent{
    background: #FFF;
    border-radius: 6px;
    padding: 15px;
    position: relative;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important;
    .dark & {
        background: #424242 !important;
    } 
    .form{
        // max-width: 600px;
        margin: auto;
    }
    .paddingForm{
        padding: 20px 0 20px;
    }
    &.full{
        .form{
            max-width: 94%;
            min-width: 700px;
            margin: auto;
        }
    }
    &.full100{
        .form{
            max-width: 100%;
            min-width: 700px;
            margin: auto;
        }
    }    
}

.heightBody{
    height: 300px;
}
.marginLeft10{
    margin-left: 10px;
}
.picker{
    width: 100%;
    .MuiFormControl-root{
        width: 100%;
        margin-top: 8px !important;
        margin-bottom: 30px !important;
        .MuiFormLabel-root{
            background:#FFF;
            position: absolute;
            left: 0;
            transform:translate(14px, -6px) scale(0.75);
            display: inline-block;
            z-index: 9;
            padding: 0 5px;
        }
        .MuiInputBase-root{
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.23);
            padding: 3px 14px;
            border-radius: 3px;
            margin-top: 0;
            &:before,&:after{
                display: none;
            }
        }
    }
    &:first-child{
        margin-left: 0 !important;
    }    
    &:last-child{
        margin-left: 10px;
    }
}
.buttonDownload{
    position: absolute !important;
    right: 20px;
    top: 20px;
    z-index: 1;
}
.box_content_auto{
    overflow: auto;
    height: calc( 100% - 100px);
    padding-right: 10px;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1; 
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }    
}
.autoComplete{
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.0988309);
    background: red;
}
.array-input-list{
    margin-top: 0 !important;
    label.MuiInputLabel-shrink{
        opacity: 0;
    }
    label.MuiInputLabel-outlined{
        opacity: 1;
    }
    &.create{
        ul{
            margin-top: 0;
        }
    }
}
.editor{
    height: 120px !important;
}
.gallery-figcaption .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button{
    border: 0;
}
.previews{
    margin-left: 10px;
    // width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.sizeImage{
    max-height: 110px;
    max-width: 100px;
    img{
        max-height: 110px;
        max-width: 100px;
    }
}
.errorInput {
    padding-bottom: 10px !important;
    .MuiFormHelperText-root.Mui-error{
        position: absolute;
        bottom: -15px;
        left: 0;
    }
}
#invoice-n-master {
    //height: 35px;
    padding-top: 21px;
}
#invoice-n-master-label {
    margin-top: -20px;
}